html {
  overflow: auto !important;
}

body {
  font-family: -apple-system, blinkmacsystemfont, segoe ui, helvetica, arial, sans-serif, apple color emoji,
    segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root abbr[title] {
  text-decoration: none;
  border-bottom: 0.1em dotted;
}
